import React from "react"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import Highlight from "react-highlight.js"

import "../../styles/blog.css"
import "../../styles/atom-one-dark-reasonable.css";

export default function BlogPostJsonbPostgresqlRails() {
  return (
    <Layout>
      <SEO title="JSONB with PostgreSQL and Rails | Primespot Blog" />
      <main>
        <h1>JSONB with PostgreSQL and Rails</h1>
        <p className="text-gray-600 mt-2 mb-4" style={{ fontSize: "16px" }}>
          Primespot Engineering - May 20, 2020
        </p>
        <div className="mb-4">
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-teal-100 text-teal-800">
            Engineering
          </span>
        </div>
        <p>
          PostgreSQL supports some excellent JSON features. With these features,
          we are able to replace some of what a NoSQL database brings to the
          table.
        </p>
        <p>
          It’s worth noting that these features are somewhat basic and don’t
          entirely replace a document storage database such as MongoDB. However,
          if your data is mostly relational, and you only need some sprinkles of
          JSON, these features might cover your needs.
        </p>
        <p>
          First of all, PostgreSQL offers two JSON data types: json and jsonb.
        </p>
        <p>
          The json data type stores a JSON object as a string in whichever way
          it was fed to PostgreSQL. Since the data is stored as a string, the
          order of the keys of the objects will be preserved.
        </p>
        <p>
          The jsonb data type is stored as a binary representation of the data
          fed to PostgreSQL. This allows for faster querying and more advanced
          indexing. It comes at the expense of slower data build time and
          additional space requirements, though.
        </p>
        <p>
          Furthermore, data stored as jsonb will be stripped of leading and
          trailing whitespace. The order of the keys won’t be preserved. And
          duplicate keys will not be stored.
        </p>
        <p>
          Once data is stored in a jsonb column, special PostgreSQL operators
          are used to perform queries.
        </p>
        <h2>PostgreSQL jsonb operators</h2>
        <p>
          Some of the most commonly used operators can be found in the table
          below.
        </p>
        <OperatorTable />
        <h2>Examples</h2>
        <p>Here are some examples of these operators in action.</p>
        <Highlight language="SQL">
          SELECT data->'title' AS title FROM books;
        </Highlight>
        <Highlight language="SQL">
          SELECT * FROM books WHERE data->'published' = 'false';
        </Highlight>

        <Highlight language="SQL">
          SELECT * FROM books WHERE data->’books’ ? ‘readers’;
        </Highlight>

        <Highlight language="SQL">
          SELECT * FROM books WHERE data->’books’->’genre’ ?| array[drama,
          horror];
        </Highlight>
        <Highlight language="SQL">
          SELECT * FROM books WHERE data->’books’->’tags’ ?& array[new, top];
        </Highlight>
        <Highlight language="SQL">
          SELECT * FROM books WHERE data->’books’->’genre’ ?| array[drama,
          horror];
        </Highlight>
        <Highlight language="SQL">
          SELECT * FROM books WHERE data->’specials’ @> ‘[“discounts”]’::jsonb
        </Highlight>

        <h2>Usage with Rails</h2>
        <p>
          Rails provides some support for using jsonb with PostgreSQL. The jsonb
          data type exists while creating migrations. Rails doesn’t provide any
          ActiveRecord helpers for querying jsonb columns, though. You have to
          execute the queries with SQL.
        </p>
        <p>Here are a few examples.</p>

        <div className="mb-4">
          <Highlight language="rails">
            Model.where("data @> ?",{" "}
            {`[{name: "To Kill a Mockingbird"}].to_json`})
          </Highlight>
          <Highlight language="rails">
            Model.where("data @> ?", {`{"interests": ["programming"]}.to_json)`}
          </Highlight>
          <Highlight language="rails">
            Model.where("data -> 'customers' @> ?",{" "}
            {`[{name: "david"}].to_json)`}
          </Highlight>
        </div>
        <p>
          Notice here we are using the to_json method to make the value
          compatible with PostgreSQL’s jsonb querying capabilities.
        </p>
        <h2>Raw SQL queries in Rails</h2>
        <p>
          For more advanced jsonb queries, you can execute raw SQL instead of
          going through Active Record. Rails provides the following method for
          executing a raw SQL query.
        </p>
        <div className="mb-4">
          <Highlight language="rails">
            ActiveRecord::Base.connection.execute()
          </Highlight>
        </div>
        <p>This method will return an array of results.</p>
        <h2>Further reading and references</h2>
        <p>
          <a href="https://www.youtube.com/watch?v=p9RItyeKbLQ">https://www.youtube.com/watch?v=p9RItyeKbLQ</a>
        </p>
        <p>
          <a href="https://www.youtube.com/watch?v=yrxCZLAN63E">https://www.youtube.com/watch?v=yrxCZLAN63E</a>
        </p>
        <p>
          <a href="https://www.postgresql.org/docs/9.5/functions-json.html">https://www.postgresql.org/docs/9.5/functions-json.html</a>
        </p>
        <p>
          <a href="https://nandovieira.com/using-postgresql-and-jsonb-with-ruby-on-rails">https://nandovieira.com/using-postgresql-and-jsonb-with-ruby-on-rails</a>
        </p>
        <p>
          <a href="https://gist.github.com/mankind/1802dbb64fc24be33d434d593afd6221">https://gist.github.com/mankind/1802dbb64fc24be33d434d593afd6221</a>
        </p>
      </main>
    </Layout>
  )
}

function OperatorTable() {
  return (
    <div className="flex flex-col my-6">
      <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Operator
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Function
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Example
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white">
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                  ->
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  Query
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  data->'books'
                </td>
              </tr>
              <tr className="bg-white">
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                  ->>
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  Query as text
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  data->'books'->>0
                </td>
              </tr>
              <tr className="bg-white">
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                  ?
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  Existence
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  data ? 'books'
                </td>
              </tr>
              <tr className="bg-white">
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                  ?|
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  Any exists
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  data ?| array['main', 'other']
                </td>
              </tr>
              <tr className="bg-white">
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                  ?&
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  All exists
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  data ?& array['main', 'other']
                </td>
              </tr>
              <tr className="bg-white">
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                  @>
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  Containment
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  data->'books' @> '["The Call of the Wild"]'
                </td>
              </tr>
              <tr className="bg-white">
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                  #>
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  Path
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  data->'settings' #> '{"{top,nested}"}'
                </td>
              </tr>
              <tr className="bg-white">
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                  #>>
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  Path as text
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  data->'settings' #>> '{"{top,nested}"}'
                </td>
              </tr>
              <tr className="bg-white">
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                  ||
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  Concatenation
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                  data || {`{ "a": 2 }`}'
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
